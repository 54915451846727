<template>
  <Layout>
    <el-form :model="formData">
      <el-form-item>
        <el-button
          type="primary"
          @click="handleCreatePrize"
        >
          <i class="el-icon-plus" />
          添加商品
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="listInfo.loading"
      :data="listInfo.list"
      stripe
      style="width: 100%"
    >
      <el-table-column
        label="产品编号"
        prop="id"
        min-width="120px"
      />
      <el-table-column
        label="产品标题"
        prop="prizeTitle"
        min-width="200px"
      />
      <!-- <el-table-column
        label="参与人次"
        prop="sales"
        min-width="80px"
      /> -->
      <el-table-column
        label="累计销量"
        prop="sales"
        min-width="80px"
      />
      <el-table-column
        label="创建时间"
        prop="createTime"
        width="180px"
      />
      <el-table-column
        label="操作"
        width="180px"
      >
        <template v-slot="{row}">
          <el-button
            type="primary"
            plain
            @click="handleEditPrize(row.id)"
          >
            <i class="el-icon-edit" />
          </el-button>
          <el-button @click="handleDeleteOneDollarPrize(row.id)">
            <i class="el-icon-delete" />
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      :total="listInfo.total"
      :page.sync="formData.pageNum"
      :limit.sync="formData.pageSize"
      @pagination="queryDataList"
    />
  </Layout>
</template>

<script>
import {
  getOneDollarPrizeList,
  deleteOneDollarPrize
} from '@/api/LuckyBuy.js'

export default {
  data () {
    return {
      formData: {
        pageNum: 1,
        pageSize: 20
      },
      listInfo: {
        list: [],
        total: 0,
        loading: false
      }
    }
  },
  created () {
    this.queryDataList()
  },
  methods: {
    queryDataList () {
      const formData = this.formData
      this.listInfo.loading = true
      getOneDollarPrizeList(formData)
        .then(res => {
          if (res.code === 200) {
            this.listInfo.list = res.data.list || []
            this.listInfo.total = res.data.total || 0
          }
        })
        .finally(() => {
          this.listInfo.loading = false
        })
    },
    handleDeleteOneDollarPrize (id) {
      deleteOneDollarPrize({ id })
        .then(res => {
          if (res.code === 200) {
            this.$message.success('删除成功')
            this.queryDataList()
          }
        })
    },
    handleCreatePrize () {
      this.$router.push({
        name: 'PrizeCreate'
      })
    },
    handleEditPrize (id) {
      this.$router.push({
        name: 'PrizeEdit',
        query: {
          id
        }
      })
    }
  }
}
</script>

<style>

</style>
